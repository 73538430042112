<template>
  <div id="diasdisponibles" class="diasdisponibles">
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12 md12>
          <v-card class="mb-4">
            <v-toolbar :color="custom_template ? 'primary' : 'info'" dark flat dense cad>
              <v-toolbar-title class="subheading">{{ $t('agenda.disponible') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider />

            <v-card-text class="">
              <diasdisponibles
                :disponibles="disponibles"
                :especialidades="especialidades"
                :centros="centros"
                :idagenda="agenda_id"
                :profesionales="profesionales"
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>

    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-tour name="myTour" :steps="steps" :options="myOptions" />
  </div>
</template>

<script>
import Diasdisponibles from '@/components/ehealth/ajustes/Diasdisponibles.vue';
import { getHeader, calendarioEspecialidadIndex, indexDiasDisponibles } from '../../config/config';

export default {
  components: {
    Diasdisponibles,
  },
  data: () => ({
    calendario: [],
    dialogSalirSinGuardar: false,
    pageNext: {},
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    agenda_id: 0,
    menu: false,
    time: null,
    disponibles: [],
    disabledButton: false,
    arrayEvents: null,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    steps: [
      { target: '[data-v-step="0"]', content: '' },
      { target: '[data-v-step="1"]', content: '' },
    ],
    myOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: 'Skip tour',
        buttonPrevious: 'Previous',
        buttonNext: 'Next',
        buttonStop: 'Finish',
      },
    },
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
    colorPrimario:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== ''
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['color']
        : 'blue',
    especialidades: [],
    profesionales: [],
    centros: [
      { id: 1, name: 'Madrid' },
      { id: 2, name: 'Barcelona' },
    ],
  }),
  created() {
    this.loading = true;
    this.getEspecialidades();
    this.getDisponibles();
  },

  methods: {
    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
    getDisponibles() {
      this.loading = true;
      this.$http.post(indexDiasDisponibles, { user_id: this.authUser.id }, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data.estado === 1) {
          this.loading = false;
          this.disponibles = response.data.disponibles;
          this.profesionales = response.data.profesionales;
          this.profesionales.unshift({ id: 0, name: '-' });
        }
      });
    },
    getEspecialidades() {
      this.$http
        .post(
          calendarioEspecialidadIndex,
          { user_id: this.authUser.id, accion: 'index', language: this.$i18n.locale },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.loading = false;
            this.especialidades = response.data.especialidades;
            this.profesionales = response.data.profesionales;
            this.profesionales.unshift({ id: 0, name: '-' });
          }
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap

  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);
  &-detail
    min-width:300px
    border-left:1px solid #eee
</style>
<style scoped lang="css">
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.v-picker__title primary {
  display: none !important;
}
.btn_float {
  z-index: 5;
  right: 8px;
  bottom: 60px;
}

.tabla_cal th {
  text-align: center;
  color: #27b0e9;
  font-weight: 100;
  font-size: 12px;
}
</style>
