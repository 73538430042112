<template>
  <div id="diasdisponibles">
    <div>
      <v-toolbar flat color="white">
        <v-spacer />
        <v-dialog v-model="dialog" max-width="500px" scrollable>
          <template v-slot:activator="{ on }">
            <v-btn :color="custom_template ? 'primary' : 'info'" dark class="mb-2" v-on="on"
              ><v-icon dark>mdi-plus</v-icon> {{ $t('agenda.nuevo_disponible') }}</v-btn
            >
          </template>
          <v-card>
            <v-card-title class="hidden-xs-only">
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 class="hidden-sm-and-up">
                    <span class="titulo_mobile">{{ formTitle }}</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout class="filtroInforme">
                      <v-flex xs6>
                        <v-select
                          v-model="editedItem.especialidad_id"
                          :items="especialidades"
                          :label="$t('agenda.especialidad')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                          :return-value.sync="editedItem.especialidad_id"
                        />
                      </v-flex>
                      <v-flex xs6>
                        <v-select
                          v-model="editedItem.centro_id"
                          :items="centros"
                          :label="$t('common.centro')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.fechaInicio"
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="iniFormatted"
                          :label="$t('common.date') + '*'"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          @blur="fini = parseDate(iniFormatted)"
                        />
                      </template>

                      <v-date-picker
                        v-model="fini"
                        :locale="this.$i18n.locale"
                        first-day-of-week="1"
                        :min="new Date().toISOString().substr(0, 10)"
                        no-title
                        scrollable
                        @input="$refs.menu.save(fini)"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      v-model="editedItem.profesional_id"
                      :items="profesionales"
                      :label="$t('user.prof_sanitario')"
                      auto
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </v-flex>
                  <v-flex xs6 sm6 lg6>
                    <v-text-field
                      v-model="editedItem.horaInicio"
                      class="hora_nueva_video"
                      type="time"
                      :label="$t('agenda.horainicio') + '*'"
                      :placeholder="$t('agenda.horainicio')"
                      prepend-icon="mdi-clock-outline"
                    />
                  </v-flex>
                  <v-flex xs6 sm6 lg6>
                    <v-text-field
                      v-model="editedItem.horaFin"
                      class="hora_nueva_video"
                      type="time"
                      :label="$t('agenda.horafin') + '*'"
                      :placeholder="$t('agenda.horafin')"
                      prepend-icon="mdi-clock-outline"
                      @change="revisaHoraFin"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 lg12>
                    <v-textarea v-model="editedItem.detalles" :label="$t('common.detalles')" rows="2" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn :color="custom_template ? 'primary darken-1' : 'blue darken-1'" text @click.native="close">{{
                $t('common.cancel')
              }}</v-btn>
              <v-btn :color="custom_template ? 'primary darken-1' : 'blue darken-1'" text @click.native="save">{{
                $t('common.save')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="disponibles"
        class="elevation-1"
        :footer-props="{
          itemsPerPageText: $t('datatable.rows_per_page'),
          itemsPerPageAllText: $t('datatable.all'),
          pageText: '',
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.fecha_inicio }}</td>
            <td class="text-center">{{ props.item.horaInicio }} - {{ props.item.horaFin }}</td>
            <td>{{ getEspecialidad(props.item.especialidad_id) }}</td>
            <td>{{ props.item.detalles }}</td>
            <td class="justify-center layout px-0">
              <v-icon small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
              <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          <v-icon>mdi-calendar-blank</v-icon> {{ $t('agenda.sin_disponibles', { tipo: $t('agenda.disponible') }) }}
        </template>
      </v-data-table>
    </div>

    <!-- Modal Eliminar No Disponible -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px" scrollable>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('agenda.delete_disponible') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ $t('agenda.confirm_delete_disponible') }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click.native="
                dialog_delete.dialog = false;
                borrarDisponible();
              "
              ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>

    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import { crearDisponible, editarDisponible, eliminarDisponible, getHeader } from '../../../config/config';

export default {
  name: 'Disponibles',
  props: {
    disponibles: {
      type: Array,
    },
    especialidades: {
      type: Array,
    },
    centros: {
      type: Array,
    },
    profesionales: {
      type: Array,
    },
    idagenda: {
      type: Number,
    },
  },

  data() {
    return {
      fini: null,
      iniFormatted: null,
      dialog: false,
      menu: false,
      headers: [
        { text: this.$t('common.date'), value: 'fechaInicio' },
        { text: this.$t('common.hora'), value: 'hora', class: 'text-center', sortable: false },
        { text: this.$t('agenda.especialidad'), value: 'especialidad_id' },
        { text: this.$t('common.detalles'), value: 'detalles' },
        { text: this.$t('common.actions'), value: '', sortable: false, class: 'text-center' },
      ],
      items: this.disponibles,
      editedIndex: -1,
      editedItem: {
        id: 0,
        fechaInicio: null,
        horaInicio: null,
        horaFin: null,
        detalles: '',
        especialidad_id: 0,
        centro_id: 0,
        profesional: 0,
      },
      defaultItem: {
        id: 0,
        fechaInicio: null,
        horaInicio: null,
        horaFin: null,
        detalles: '',
        especialidad_id: 0,
        centro_id: 0,
        profesional: 0,
      },
      snackbar: false,
      dialog_delete: {
        name: '',
        dialog: false,
      },
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      dataPost: {
        user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        indice: 0,
        disponible: {},
      },
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
          ? true
          : false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('agenda.nuevo_disponible') : this.$t('agenda.editar_disponible');
    },
  },

  watch: {
    fini(_val) {
      this.iniFormatted = this.formatDate(this.fini);
    },
  },

  methods: {
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    getEspecialidad(id) {
      const valObj = this.especialidades.filter(function(elem) {
        if (elem.id === id) return elem.name;
      });

      if (typeof valObj[0] !== 'undefined') return valObj[0].name;

      return '';
    },

    revisaHoraFin() {
      if (this.editedItem.horaFin < this.editedItem.horaInicio) {
        this.editedItem.horaFin = this.editedItem.horaInicio;
      }
    },

    editItem(item) {
      this.editedIndex = this.disponibles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.iniFormatted = this.formatDate(this.editedItem.fechaInicio);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.disponibles.indexOf(item);
      this.dialog_delete.dialog = true;
    },

    borrarDisponible() {
      this.dataPost.disponible = this.editedItem.id;
      this.$http.post(eliminarDisponible, this.dataPost, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data.estado === 1) {
          this.disponibles.splice(this.editedIndex, 1);
          this.alerta.texto = this.$t('success.delete_common');
          this.alerta.color = 'success';
          this.alerta.icono = 'mdi-check';
          this.snackbar = true;
        } else {
          this.alerta.texto = this.$t('errors.try_again');
          this.alerta.color = 'error';
          this.alerta.icono = 'mdi-alert-circle';
        }
        this.snackbar = true;
      });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (
        this.editedItem.fechaInicio === '' ||
        this.editedItem.fechaInicio === null ||
        this.editedItem.horaFin === null ||
        this.editedItem.horaInicio === null ||
        this.editedItem.especialidad_id === 0 ||
        this.editedItem.centro_id === 0 ||
        this.editedItem.profesional_id === 0
      ) {
        this.alerta.texto = this.$t('errors.form_invalid');
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-alert-circle';
        this.snackbar = true;
      } else if (this.editedItem.horaFin <= this.editedItem.horaInicio) {
        this.alerta.texto = this.$t('errors.horaFinMenorHoraInicio');
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-alert-circle';
        this.snackbar = true;
      } else {
        this.dataPost.disponible = this.editedItem;
        this.dataPost.indice = this.editedIndex;
        const accionDisponible = this.editedIndex > -1 ? editarDisponible : crearDisponible;
        this.$http.post(accionDisponible, this.dataPost, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.alerta.texto = this.$t('success.save_common');
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-check';
            if (this.dataPost.indice > -1) {
              Object.assign(this.disponibles[this.dataPost.indice], this.dataPost.disponible);
              this.disponibles.push({});
              this.disponibles.pop();
            } else {
              this.disponibles.push(response.data.disponible);
            }
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        });
        this.close();
      }
    },
  },
};
</script>

<style>
.titulo_mobile {
  font-family: Roboto;
  font-weight: 600 !important;
  text-transform: uppercase;
}
</style>
